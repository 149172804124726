import React, {useState, useEffect} from 'react'
import { WalletIcon } from '@heroicons/react/24/solid';
import { useWallet, useWallets } from '@wallet-standard/react';
import { getAddress, AddressPurpose, BitcoinNetworkType } from 'sats-connect';
import { Wallet, WalletWithFeatures } from '@wallet-standard/base';
import { useSelector, useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import SessionStorage, { SessionsStorageKeys } from "../services/session-storage";
import { setAddress } from '../store/action';
import { formatAddress } from '../util/format-data';
import { DocumentDuplicateIcon, ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/solid';

import { saveDataToStorage } from '../util/localstorage-util';

import logo from '../assets/imgs/logo.png';
import mintImg from '../assets/imgs/mintimg.png';

export default function Connect() {

  const { wallets } = useWallets();
  // const { setWallet, wallet: meWallet } = useWallet();
  const wallet = useSelector(state => state.wallet);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const handleOpen = (value) => {
    setShow(value);
  }

  const tapWalletConnect = async() => {
    setShow(false);
    if (typeof window.tapwallet == 'undefined') {
      toast.error("Please install tap wallet!");
    }
    else
    {
      try {
        SessionStorage.set(SessionsStorageKeys.DOMAIN, "unisat.io");
        let accounts = await window.tapwallet.requestAccounts();
        let address = await window.tapwallet.getAccounts();
        let pubkey = await window.tapwallet.getPublicKey();
        console.log("--pubkey--", pubkey);
        dispatch(setAddress({
            xWalletName : "tapwallet",
            domain : "tapwallet",
            nostrOrdinalsAddress : address[0].trim(),
            nostrPaymentAddress : address[0].trim(),
            ordinalsPublicKey : pubkey.trim(),
            paymentPublicKey : pubkey.trim()
        }));

        saveDataToStorage('tapwallet', address[0].trim());
        
        window.tapwallet.on('accountsChanged', handleTapwalletAccountsChanged);
        return () => {
          window.tapwallet.removeListener('accountsChanged', handleTapwalletAccountsChanged)
        }
      } catch(e) {
        console.log(e.response?.data?.message || e.message);
      }
    }
  }

  const handleTapwalletAccountsChanged =  async (_accounts) => {
    if (_accounts.length > 0) {
      let address = await window.tapwallet.getAccounts();
      let pubkey = await window.tapwallet.getPublicKey();
      dispatch(setAddress({
        xWalletName : "tapwallet",
        domain : "tapwallet",
        nostrOrdinalsAddress : address[0].trim(),
        nostrPaymentAddress : address[0].trim(),
        ordinalsPublicKey : pubkey.trim(),
        paymentPublicKey : pubkey.trim()
      }));
      saveDataToStorage('tapwallet', address[0].trim());
    } else {
      dispatch(setAddress({
        xWalletName : "tapwallet",
        domain : "tapwallet",
        nostrOrdinalsAddress : "",
        nostrPaymentAddress : "",
        ordinalsPublicKey : "",
        paymentPublicKey : ""
      }));
      saveDataToStorage('', '');
    }
  }

  const unisatWalletConnect = async() => {
    setShow(false);
    if (typeof window.unisat == 'undefined') {
      toast.error("Please install unisat wallet!");
    }
    else
    {
      try {
        SessionStorage.set(SessionsStorageKeys.DOMAIN, "unisat.io");
        let accounts = await window.unisat.requestAccounts();
        let address = await window.unisat.getAccounts();
        let pubkey = await window.unisat.getPublicKey();
        console.log("--pubkey--", pubkey);
        dispatch(setAddress({
            xWalletName : "unisat",
            domain : "unisat",
            nostrOrdinalsAddress : address[0].trim(),
            nostrPaymentAddress : address[0].trim(),
            ordinalsPublicKey : pubkey.trim(),
            paymentPublicKey : pubkey.trim()
        }));

        saveDataToStorage('unisat', address[0].trim());
        
        window.unisat.on('accountsChanged', handleUnisatAccountsChanged);
        return () => {
          window.unisat.removeListener('accountsChanged', handleUnisatAccountsChanged)
        }
      } catch(e) {
        console.log(e.response?.data?.message || e.message);
      }
    }
  }

  const handleUnisatAccountsChanged =  async (_accounts) => {
    if (_accounts.length > 0) {
      let address = await window.unisat.getAccounts();
      let pubkey = await window.unisat.getPublicKey();
      dispatch(setAddress({
        xWalletName : "unisat",
        domain : "unisat",
        nostrOrdinalsAddress : address[0].trim(),
        nostrPaymentAddress : address[0].trim(),
        ordinalsPublicKey : pubkey.trim(),
        paymentPublicKey : pubkey.trim()
      }));
      saveDataToStorage('unisat', address[0].trim());
    } else {
      dispatch(setAddress({
        xWalletName : "unisat",
        domain : "unisat",
        nostrOrdinalsAddress : "",
        nostrPaymentAddress : "",
        ordinalsPublicKey : "",
        paymentPublicKey : ""
      }));
      saveDataToStorage('', '');
    }
  }

  const xverseWalletConnect = async () => {
    setShow(false);
    const getAddressOptions = {
      payload: {
        purposes: ['ordinals', 'payment'],
        message: 'Address for receiving Ordinals and payments',
        network: {
          type:'Mainnet'
        },
      },
      onFinish: (response) => {
        console.log(response)
        if (response.addresses[0].purpose == "ordinals")
        {
          dispatch(setAddress({
            xWalletName : "xverseWallet",
            domain : "xverseWallet",
            nostrOrdinalsAddress : response.addresses[0].address.trim(),
            nostrPaymentAddress : response.addresses[1].address.trim(),
            ordinalsPublicKey : response.addresses[0].publicKey.trim(),
            paymentPublicKey : response.addresses[1].publicKey.trim()
          }));
          saveDataToStorage('xverseWallet', response.addresses[0].address.trim());
        }
        else
        {
          dispatch(setAddress({
            xWalletName : "xverseWallet",
            domain : "xverseWallet",
            nostrOrdinalsAddress : response.addresses[1].address.trim(),
            nostrPaymentAddress : response.addresses[0].address.trim(),
            ordinalsPublicKey : response.addresses[1].publicKey.trim(),
            paymentPublicKey : response.addresses[0].publicKey.trim()
          }));
          saveDataToStorage('xverseWallet', response.addresses[1].address.trim());
        }
      },
      onCancel: () => alert('Request canceled'),
      }
        
    await getAddress(getAddressOptions);
  }

  const handleXverseWalletAccountsChanged =  async (_accounts) => {
    if (_accounts.length > 0) {
      let address = await window.okxwallet.bitcoin.getAccounts();
      let pubkey = await window.okxwallet.bitcoin.getPublicKey();
      dispatch(setAddress({
        xWalletName : "xverseWallet",
        domain : "xverseWallet",
        nostrOrdinalsAddress : address[0].trim(),
        nostrPaymentAddress : address[0].trim(),
        ordinalsPublicKey : pubkey.trim(),
        paymentPublicKey : pubkey.trim()
      }));
    } else {
      dispatch(setAddress({
        xWalletName : "xverseWallet",
        domain : "xverseWallet",
        nostrOrdinalsAddress : "",
        nostrPaymentAddress : "",
        ordinalsPublicKey : "",
        paymentPublicKey : ""
      }));
    }
  }

  const okxWalletConnect = async () => {
    setShow(false);
    if (typeof window.okxwallet == 'undefined') {
      toast.error("Please install okxwallet!");
    }
    else
    {
      try {
        SessionStorage.set(SessionsStorageKeys.DOMAIN, "unisat.io");
        let accounts = await window.okxwallet.bitcoin.requestAccounts();
        let address = await window.okxwallet.bitcoin.getAccounts();
        let pubkey = await window.okxwallet.bitcoin.getPublicKey();
        dispatch(setAddress({
            xWalletName : "okxwallet",
            domain : "okxwallet",
            nostrOrdinalsAddress : address[0].trim(),
            nostrPaymentAddress : address[0].trim(),
            ordinalsPublicKey : pubkey.trim(),
            paymentPublicKey : pubkey.trim()
        }));

        saveDataToStorage('okxwallet', address[0].trim());
        
        window.okxwallet.bitcoin.on('accountsChanged', handleOkxWalletAccountsChanged);
        return () => {
          window.okxwallet.bitcoin.removeListener('accountsChanged', handleOkxWalletAccountsChanged)
        }
      } catch(e) {
        console.log(e.response?.data?.message || e.message);
      }
    }
  }

  const magicEdenConnect = async () => {
    setShow(false);

    let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);
    if (magicedenWallets.length > 0) {
      try {
        SessionStorage.set(SessionsStorageKeys.DOMAIN, "unisat.io");

        await getAddress({
          getProvider: async () =>
            magicedenWallets[0].features['sats-connect:'].provider,
          payload: {
              purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
              message: 'Address for receiving Ordinals and payments',
              network: {
                  type: BitcoinNetworkType.Mainnet,
              },
          },
          onFinish: (response) => {
              console.log("---magic wallet connected---", response)
              let res = response.addresses;
              if (res[0].purpose == "ordinals") {
                dispatch(setAddress({
                  xWalletName : "magiceden",
                  domain : "magiceden",
                  nostrOrdinalsAddress : res[0].address.trim(),
                  nostrPaymentAddress : res[1].address.trim(),
                  ordinalsPublicKey : res[0].publicKey.trim(),
                  paymentPublicKey : res[1].publicKey.trim()
                }));
  
                saveDataToStorage('magiceden', res[0].address.trim());
              }
              else {
                dispatch(setAddress({
                  xWalletName : "magiceden",
                  domain : "magiceden",
                  nostrOrdinalsAddress : res[1].address.trim(),
                  nostrPaymentAddress : res[0].address.trim(),
                  ordinalsPublicKey : res[1].publicKey.trim(),
                  paymentPublicKey : res[0].publicKey.trim()
                }));
  
                saveDataToStorage('magiceden', res[1].address.trim());
              }
          },
          onCancel: () => {
              alert('Request canceled');
          },
        });
        
      } catch(e) {
        console.log(e.response?.data?.message || e.message);
      }
    }
  }

  const handleOkxWalletAccountsChanged =  async (_accounts) => {
    if (_accounts.length > 0) {
      let address = await window.okxwallet.bitcoin.getAccounts();
      let pubkey = await window.okxwallet.bitcoin.getPublicKey();
      dispatch(setAddress({
        xWalletName : "okxwallet",
        domain : "okxwallet",
        nostrOrdinalsAddress : address[0].trim(),
        nostrPaymentAddress : address[0].trim(),
        ordinalsPublicKey : pubkey.trim(),
        paymentPublicKey : pubkey.trim()
      }));
      saveDataToStorage('okxwallet', address[0].trim());
    } else {
      dispatch(setAddress({
        xWalletName : "okxwallet",
        domain : "okxwallet",
        nostrOrdinalsAddress : "",
        nostrPaymentAddress : "",
        ordinalsPublicKey : "",
        paymentPublicKey : ""
      }));
      saveDataToStorage('', '');
    }
  }

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        toast.success('Address copied to clipboard!')
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  const disconnectWallet = () => {
    dispatch(setAddress({
      xWalletName : "",
      domain : "",
      nostrOrdinalsAddress : "",
      nostrPaymentAddress : "",
      ordinalsPublicKey : "",
      paymentPublicKey : ""
    }));
    saveDataToStorage('', '');
  }

  return (
    <div>
      {
        wallet.nostrOrdinalsAddress != "" ?
          <div className="flex flex-row gap-2 items-center px-4 py-[10px] rounded-md cursor-pointer border-[2px] border-red-900 border-solid text-white">
            <DocumentDuplicateIcon className="h-5 w-5 cursor-pointer" onClick={() => {handleCopy(wallet.nostrOrdinalsAddress)}} />
            <span className="font-bold">{formatAddress(wallet.nostrOrdinalsAddress)}</span>
            <ArrowRightEndOnRectangleIcon className="h-6 w-6 cursor-pointer" onClick={() => {disconnectWallet()}} />
          </div>
          :
          <div className="flex flex-row font-semibold gap-2 items-center text-white px-4 py-[10px] rounded-md cursor-pointer border-[2px] border-red-900 border-solid" onClick={() => handleOpen(true)}>
            <WalletIcon strokeWidth={2} className="h-5 w-5" />
            <span>Connect</span>
          </div>
      }
      <Dialog
        open={show}
        size={"xs"}
        handler={() => handleOpen(false)}
      >
        <DialogHeader>
          <div className="flex flex-row w-full justify-center items-center mt-5 text-[32px] font-bold gap-4">
            <img src={mintImg} className="w-[36px] h-[36px]"/>
            <div>Select Wallet</div>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-3 px-10">
            <div className="flex flex-row w-full items-center border-solid border-[1px] border-black rounded-lg hover:bg-[#F0F0F0] px-6 py-2 cursor-pointer" onClick={tapWalletConnect}>
              <img src={logo} className="w-[50px] h-[50px] bg-black p-2 rounded-md"/>
              <div className=" text-black font-semibold text-center text-[20px] w-full">Tap Wallet</div>
            </div>
            <div className="flex flex-row w-full items-center border-solid border-[1px] border-black rounded-lg hover:bg-[#F0F0F0] px-6 py-2 cursor-pointer" onClick={unisatWalletConnect}>
              <img src={"https://next-cdn.unisat.io/_/24/logo/color.svg"} className="w-[50px] h-[50px] bg-black p-2 rounded-md"/>
              <div className=" text-black font-semibold text-center text-[20px] w-full">Unisat Wallet</div>
            </div>
            <div className="flex flex-row w-full items-center border-solid border-[1px] border-black rounded-lg hover:bg-[#F0F0F0] px-6 py-2 cursor-pointer" onClick={xverseWalletConnect}>
              <img src={"https://next-cdn.unisat.io/_/24/img/wallet/xverse_icon_whitecolor.svg"} className="w-[50px] h-[50px] bg-black p-2 rounded-md"/>
              <div className=" text-black font-semibold text-center text-[20px] w-full">Xverse Wallet</div>
            </div>
            <div className="flex flex-row w-full items-center border-solid border-[1px] border-black rounded-lg hover:bg-[#F0F0F0] px-6 py-2 cursor-pointer" onClick={okxWalletConnect}>
              <img src={"https://next-cdn.unisat.io/_/24/img/wallet/okx_wallet_icon.svg"} className="w-[50px] h-[50px] bg-black p-2 rounded-md"/>
              <div className=" text-black font-semibold text-center text-[20px] w-full">OKX Wallet</div>
            </div>
            <div className="flex flex-row w-full items-center border-solid border-[1px] border-black rounded-lg hover:bg-[#F0F0F0] px-6 py-2 cursor-pointer" onClick={magicEdenConnect}>
              <img src={"https://next-cdn.unisat.io/_/122/img/wallet/magic-eden.png"} className="w-[50px] h-[50px] bg-black p-2 rounded-md"/>
              <div className=" text-black font-semibold text-center text-[20px] w-full">Magic Eden</div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={() => handleOpen(false)}
            className="py-[12px] mr-3"
          >
            <span>Cancel</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <Toaster 
        position="top-right"
      />
    </div>
  )
}
