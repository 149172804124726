export const encodedAddressPrefix = 'main';
export const padding = 546;
export const tip = 0;
export const royalty = 80000;
// export const royalty = 444;
export const ownerAddress = 'bc1pqdfk4nvpkucccmzjs35tc40yzvn8797ruvmn46phqwlzc3mutteqfkcx8k';
export const mempoolNetwork = '';
export const include_mempool = true;
export const marketTip = 2000;
export const NUMBER_OF_DUMMY_UTXOS_TO_CREATE = 2;
export const DUMMY_UTXO_VALUE = 600;
export const MIN_UTXO_VALUE = 1000;
export const contentLength = 2552;
export const serviceFeeRate = 0.05;
export const tippingAddress = "bc1pqdfk4nvpkucccmzjs35tc40yzvn8797ruvmn46phqwlzc3mutteqfkcx8k";

